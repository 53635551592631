import { IonButton, IonContent, IonGrid, IonIcon, IonPage, IonRow } from '@ionic/react';
import React, { useState } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import styled from 'styled-components';
import img1 from '../assets/gun tracker ss 1.jpg';
import img2 from '../assets/gun tracker ss 2.jpg';
import img3 from '../assets/gun tracker ss 3.jpg';
import img4 from '../assets/gun tracker ss 4.jpg';
import img5 from '../assets/gun tracker ss 5.jpg';
import img6 from '../assets/gun tracker ss 6.jpg';
import { arrowForward, arrowBack } from 'ionicons/icons';

const CenterText = styled.div`
  text-align: center;
  width: 100%;
`;

const ImageContainer = styled.div`
  width: 50%;
  margin-left: 5%;
  height: 50%;
  border: 3px solid grey;
`;

const images = {
    1: img1,
    2: img2,
    3: img3,
    4: img4,
    5: img5,
    6: img6
  };

const GunTracker: React.FC = () => {

  const [currentImage, setCurrentImage] = useState(1);

  const cycleImage = direction => {
    if (direction === 'back') {
      currentImage === 1 ? setCurrentImage(6) : setCurrentImage(currentImage - 1);
    } else {
      currentImage === 6 ? setCurrentImage(1) : setCurrentImage(currentImage + 1);
    }
  };

  return (
    <IonPage>
      <Header gunTrackerDisabled={true} homeDisabled={false}/>
      <IonContent>
        <IonGrid>
          <IonRow>
            <CenterText>
              <h2 style={{ fontWeight: 'bold' }}>Gun Tracker</h2>
            </CenterText>
          </IonRow>
          <IonRow style={{ textAlign: 'center' }}>
            <CenterText>
              <h4>Your complete firearm and ammo inventory system.</h4>
            </CenterText>
          </IonRow>
          <IonRow>
            <div style={{ textAlign: 'center', width: '100%' }}>
            <IonButton href='https://github.com/DavidCabal/gun-tracker-downloads/releases' target='_blank' rel="noopener noreferrer">
              Download
            </IonButton>
            </div>
          </IonRow>
          <IonRow style={{ marginTop: '25px' }}>
            <IonIcon onClick={() => cycleImage('back')} icon={arrowBack}
              style={{ fontSize: '30px', marginTop: '13%', marginLeft: 'calc(20% - 30px)', cursor: 'pointer' }} />
            <ImageContainer>
              <img src={images[currentImage]} alt='screenshots' />
            </ImageContainer>
            <IonIcon onClick={() => cycleImage('forward')} icon={arrowForward}
              style={{ fontSize: '30px', marginTop: '13%', marginLeft: '5%', cursor: 'pointer' }} />
          </IonRow>
          <IonRow style={{ marginTop: '25px', textAlign: 'center', width: '70%', marginLeft: '15%' }}>
            <h5>Gun Tracker is <strong>completely free</strong> firearm and ammo tracking software available for Windows and OSX. Check out the following 
            feature list for a sampling of what you can do with Gun Tracker.</h5>
            <IonRow style={{ textAlign: 'left' }}>
              <ul>
                <li>save the make, model, color, date acquired, serial number, purchase price, and more</li>
                <li>add pictures of each gun</li>
                <li>track rounds fired, cleanings, failures, and dry fires</li>
                <li>save notes on each range and cleaning session</li>
                <li>keep track of your ammo supply</li>
                <li>export pdf and csv reports of your gun/ammo information</li>
                <li>no data is ever sent to a server...only you have access to your sensitive gun information</li>
              </ul>
            </IonRow>
          </IonRow>
          <IonRow style={{ marginTop: '25px', textAlign: 'center', width: '70%', marginLeft: '15%' }}>
            <h5>If you have an Android phone/tablet, download the companion app to keep track of your information on the go. You 
              can even sync data between the desktop app and Android app when on the same WiFi network!
            </h5>
            <br />
            <a href='https://play.google.com/store/apps/details?id=com.cabal.gunlogpro&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
              style={{ width: '15em', marginLeft: 'calc(50% - 7.5em' }} target='_blank' rel="noopener noreferrer">
                <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
            </a>
          </IonRow>
        </IonGrid>
        <div style={{ height: '30px'}} />
        <Footer position='' />
      </IonContent>
    </IonPage>
  );
};

export default GunTracker;
