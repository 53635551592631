import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';
import 'animate.css';
import Footer from '../components/Footer';
import Header from '../components/Header';

const LandingPageTitleContainer = styled.div`
  width: 100%;
  margin-top: 150px;
  text-align: center;
`;

const LandingPageTitle = styled.span`
  font-size: 24px;
  color: grey;
`;

const Home: React.FC = (props) => {

  return (
    <IonPage>
        <Header gunTrackerDisabled={false} homeDisabled={true}/>
      <IonContent>
        <LandingPageTitleContainer className='animated rubberBand'>
          <LandingPageTitle>Cabal Software</LandingPageTitle>
        </LandingPageTitleContainer>
        <Footer position='position: fixed' />
      </IonContent>
    </IonPage>
  );
};

export default Home;
