import { IonButton, IonButtons, IonHeader, IonToolbar } from '@ionic/react';
import React from 'react';

const Header = (props) => {

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons>
          <IonButton style={{ cursor: 'pointer' }} disabled={props.homeDisabled} routerLink='/home'>Home</IonButton>
          <IonButton disabled={props.gunTrackerDisabled} routerLink='/guntracker'>Gun Tracker</IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;