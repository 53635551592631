import React from 'react';
import styled from 'styled-components';

const Footer = (props) => {

  const FooterContainer = styled.div`
    width: 100%;
    text-align: center;
    ${props.position};
    bottom: 10px;
    margin-bottom: 10px;
  `;

  const Footer = styled.span`
    font-size: 12px;
    color: red;
  `;

  return (
    <FooterContainer>
      <Footer>Made with <span role='img' aria-label='heart'>❤️</span> in Nashville, TN</Footer>
    </FooterContainer>
  );
};

export default Footer;